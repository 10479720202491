import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/durchfallmythen"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf active-trail">
                        <a
                          href="/durchfallmythen/"
                          title
                          className="active-trail active"
                        >
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/richtig-essen/" title>
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>*/}
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-10" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        lineHeight: "26px",
                        margin: "20px 0 10px 0",
                        fontFamily: "'Open Sans', sans-serif",
                      }}>
                        WIE WIRKT
                        <br />
                        IMODIUM®?
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_animation_wiewirkt.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Dieses Video zeigt Ihnen, wie Imodium Ihren Darm wieder
                        in sein natürliches Gleichgewicht bringt.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/loperamid/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/durchfallmythen"
                      typeof="sioc:Item foaf:Document"
                      className="ds-2col-stacked node node-simple-page view-mode-full clearfix"
                    >
                      <div className="group-header">
                        <h1>Tipps für die Behandlung von Durchfall</h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/header_visuals_695x286_fakten_durchfall.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="firstrow">
                          <p className="subline">
                            Beim Thema Durchfallbehandlung gibt es immer noch
                            viele Missverständnisse und Unsicherheiten. Lesen
                            Sie hier einige wichtige Tipps:{" "}
                          </p>
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                            <h3>Tipp 1: Durchfall ist keine Krankheit, sondern ein Symptom.</h3>
                              <p>
                                Manche Betroffene denken, dass der Körper den
                                Durchfall zur Ausspülung krankmachender Keime
                                (Bakterien, Viren und Parasiten als Ursache
                                möglich) benutzt. Das trifft nicht zu, denn
                                Durchfall ist keine Abwehrreaktion des Körpers,
                                sondern lediglich ein Symptom. Das körpereigene
                                Immunsystem reagiert auf Krankheitserreger und
                                bekämpft diese. Oftmals sind die
                                Auslöser von Durchfall aber gar keine Keime,
                                vielmehr liegen andere, nicht-infektiöse
                                Ursachen zugrunde, wie z.B.{" "}
                                <a href="/stress-durchfall/">Stress</a>.
                              </p>
                              <h3>
                                Tipp 2: Bei Kindern ist eine schnelle
                                Durchfallbehandlung besonders wichtig.
                              </h3>
                              <p>
                                Durchfallerkrankungen entziehen dem Körper
                                erhebliche Flüssigkeits- und Elektrolytmengen.
                                Dieser Mangel schwächt Kinder deutlich stärker
                                als Erwachsene. Daher ist es bei Kindern
                                besonders wichtig, schnell für eine zusätzliche
                                Flüssigkeitszufuhr zu sorgen und den Durchfall
                                zu behandeln. Um den Mineralstoffverlust
                                auszugleichen gibt es in Ihrer Apotheke oder
                                Drogerie zusätzlich speziell für Kinder fertige
                                Elekrolytmischungen. Sollte der Durchfall bei
                                Ihrem Kind weiter anhalten, ist ein Arztbesuch
                                unerlässlich. Weitere Informationen zu{" "}
                                <a href="/durchfall-bei-kindern/">
                                  Durchfall bei Kindern
                                </a>{" "}
                                finden sie hier.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="field field-name-field-body-right field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                            <h3>Tipp 3: Verminderung der erhöhten Darmbewegung.</h3>
                              <p>
                                Entgegen häufiger Vermutungen unterbinden{" "}
                                <a
                                  href="/loperamid/"
                                  title="Durchfallmittel"
                                >
                                  Durchfallmittel
                                </a>{" "}
                                wie IMODIUM® nicht den Stuhlgang.
                                Vielmehr können sie lediglich dabei helfen, die übermässige 
                                Darmfunktion wieder zu normalisieren. Bedenken Sie jedoch, dass der
                                Darm bei Durchfall häufig vollständig entleert
                                ist und es daher bis zu zwei Tage dauern kann,
                                bis er wieder gefüllt ist.
                              </p>
                              <h3>Tipp 4: Durchfall kann ansteckend sein.</h3>
                              <p>
                                Durchfall kann durchaus ansteckend sein, wenn
                                als Auslöser eine Infektion mit Bakterien oder
                                Viren, z.B. bei{" "}
                                <a href="/gastroenteritis/">Gastroenteritis</a>,
                                besteht. Infektionen können durch Kontakt mit
                                erkrankten Personen, verunreinigten Oberflächen,
                                Gegenständen oder Lebensmittel übertragen
                                werden.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="std-site">
                        <div className="leftcol group-left"></div>
                        <div className="rightcol group-right"></div>
                      </div>
                      <div className="group-footer"></div>
                    </div>
                  </div>
                </div>
                <div id="block-block-8" className="block block-block">
                  <div className="content">
                    <h3>Welches Imodium bei welchen Beschwerden?</h3>
                    <p>
                      <img
                        alt
                        src="/sites/default/files/Produkte_Range.jpg"
                        style={{
                          width: "699px",
                          height: "274px"
                        }}
                      />
                    </p>
                    <div className="nextbtn">
                      <a className="btn" href="/imodium-produkte/">
                        <span className="start">Produktübersicht</span>
                        <span className="end">&nbsp;</span>
                      </a>
                    </div>
                    <div className="clear">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
